import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "*", redirect: "/step-1" },
  {
    path: "/step-1",
    name: "Step-1",
    component: () => import("../views/Step-1.vue")
  },
  {
    path: "/step-2",
    name: "Step-2",
    component: () => import("../views/Step-2.vue")
  },
  {
    path: "/step-3",
    name: "Step-3",
    component: () => import("../views/Step-3.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
