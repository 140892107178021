<template>
  <button :class="classes" :disabled="disabled" @click="$emit('click', $event)">
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    type: {
      type: String,
      default: "primary"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return ["base-button", `base-button--${this.type}`];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.base-button {
  border: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 24px;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 1px 1px 5px 2px $color-gray-dark;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.base-button--primary {
  background-color: $color-primary;
  color: white;
}

.base-button--secondary {
  background-color: white;
  color: $color-primary;
}
</style>
