import { errorMessageRequired, errorMessageIncorect } from "./index";

const getDefaultState = () => {
  return {
    stepValidation: {
      isValid: false,
      showErrors: false
    },
    projectAddress: {
      value: "",
      isValid: false,
      errors: []
    },
    projectPostalCode: {
      value: "",
      isValid: false,
      errors: []
    },
    projectTown: {
      value: "",
      isValid: false,
      errors: []
    },
    coordinates: {
      value: null
    },
    municipality: {
      value: "",
      isValid: false,
      errors: []
    },
    gnr: {
      value: "",
      isValid: false,
      errors: []
    },
    bnr: {
      value: "",
      isValid: false,
      errors: []
    },
    projectTypes: {
      value: [],
      isValid: false,
      errors: []
    },
    projectDescription: {
      value: "",
      isValid: false,
      errors: []
    },
    projectDrawing: {
      value: []
    },
    projectName: {
      value: ""
    }
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    updateProjectAddress(state, value) {
      state.projectAddress.value = value;
      state.projectAddress.isValid = false;
    },
    validProjectAddress(state) {
      state.projectAddress.errors = [];

      if (!state.projectAddress.value) {
        state.projectAddress.errors.push(errorMessageRequired);
      } else if (state.projectAddress.value.length < 2) {
        state.projectAddress.errors.push(errorMessageIncorect);
      } else {
        state.projectAddress.isValid = true;
      }
    },

    updateProjectPostalCode(state, value) {
      state.projectPostalCode.value = value;
      state.projectPostalCode.isValid = false;
    },
    validProjectPostalCode(state) {
      state.projectPostalCode.errors = [];

      if (!state.projectPostalCode.value) {
        state.projectPostalCode.errors.push(errorMessageRequired);
      } else if (state.projectPostalCode.value.length !== 4) {
        state.projectPostalCode.errors.push(errorMessageIncorect);
      } else {
        state.projectPostalCode.isValid = true;
      }
    },

    updateProjectTown(state, value) {
      state.projectTown.value = value;
      state.projectTown.isValid = false;
    },
    validProjectTown(state) {
      state.projectTown.errors = [];

      if (!state.projectTown.value) {
        state.projectTown.errors.push(errorMessageRequired);
      } else if (state.projectTown.value.length < 2) {
        state.projectTown.errors.push(errorMessageIncorect);
      } else {
        state.projectTown.isValid = true;
      }
    },

    resetCoordinates(state) {
      state.coordinates.value = null;
    },
    updateCoordinates(state, value) {
      state.coordinates.value = value;
    },

    updateMunicipality(state, value) {
      state.municipality.value = value;
    },
    validMunicipality(state) {
      state.municipality.errors = [];
      state.municipality.isValid = false;

      if (!state.municipality.value) {
        state.municipality.errors.push(errorMessageRequired);
      } else if (state.municipality.value.length < 2) {
        state.municipality.errors.push(errorMessageIncorect);
      } else {
        state.municipality.isValid = true;
      }
    },

    updateGnr(state, value) {
      state.gnr.value = value;
    },
    validGnr(state) {
      state.gnr.errors = [];
      state.gnr.isValid = false;

      if (!state.gnr.value) {
        state.gnr.errors.push(errorMessageRequired);
      } else {
        state.gnr.isValid = true;
      }
    },

    updateBnr(state, value) {
      state.bnr.value = value;
    },
    validBnr(state) {
      state.bnr.errors = [];
      state.bnr.isValid = false;

      if (!state.bnr.value) {
        state.bnr.errors.push(errorMessageRequired);
      } else {
        state.bnr.isValid = true;
      }
    },

    updateProjectTypes(state, value) {
      state.projectTypes.value = value;
    },
    validProjectTypes(state) {
      state.projectTypes.errors = [];
      state.projectTypes.isValid = false;

      if (!state.projectTypes.value.length) {
        state.projectTypes.errors.push("At least 1 project type is required");
      } else {
        state.projectTypes.isValid = true;
      }
    },

    updateProjectDescription(state, value) {
      state.projectDescription.value = value;
    },
    validProjectDescription(state) {
      state.projectDescription.errors = [];
      state.projectDescription.isValid = false;

      if (!state.projectDescription.value) {
        state.projectDescription.errors.push(errorMessageRequired);
      } else if (state.projectDescription.value.length < 2) {
        state.projectDescription.errors.push(errorMessageIncorect);
      } else {
        state.projectDescription.isValid = true;
      }
    },

    updateProjectDrawing(state, value) {
      state.projectDrawing.value = value;
      console.log(state.projectDrawing.value);
    },

    updateProjectName(state, value) {
      state.projectName.value = value;
    },

    updateStepValidation(state) {
      if (
        state.projectAddress.isValid &&
        state.projectPostalCode.isValid &&
        state.projectTown.isValid &&
        state.municipality.isValid &&
        state.gnr.isValid &&
        state.bnr.isValid &&
        state.projectTypes.isValid &&
        state.projectDescription.isValid
      ) {
        state.stepValidation.isValid = true;
        state.stepValidation.showErrors = false;
      } else {
        state.stepValidation.isValid = false;
        state.stepValidation.showErrors = true;
      }
    }
  },

  actions: {
    validStep({ commit, state }) {
      commit("validProjectAddress");
      commit("validProjectPostalCode");
      commit("validProjectTown");
      commit("validMunicipality");
      commit("validGnr");
      commit("validBnr");
      commit("validProjectTypes");
      commit("validProjectDescription");

      commit("updateStepValidation");

      return state.stepValidation.isValid;
    }
  }
};
