import { errorMessageRequired, errorMessageIncorect } from "./index";

const getDefaultState = () => {
  return {
    stepValidation: {
      isValid: false,
      showErrors: false
    },
    offer: {
      value: "",
      isValid: false,
      errors: []
    },
    documents: {
      value: []
    }
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    updateOffer(state, value) {
      state.offer.value = value;
    },
    validOffer(state) {
      state.offer.errors = [];
      state.offer.isValid = false;

      if (!state.offer.value) {
        state.offer.errors.push(errorMessageRequired);
      } else if (state.offer.value.length < 2) {
        state.offer.errors.push(errorMessageIncorect);
      } else {
        state.offer.isValid = true;
      }
    },

    updateDocuments(state, value) {
      state.documents.value = value;
      console.log(state.documents.value);
    },

    updateStepValidation(state) {
      if (state.offer.isValid) {
        state.stepValidation.isValid = true;
        state.stepValidation.showErrors = false;
      } else {
        state.stepValidation.isValid = false;
        state.stepValidation.showErrors = true;
      }
    }
  },

  actions: {
    validStep({ commit, state }) {
      commit("validOffer");
      commit("updateStepValidation");

      return state.stepValidation.isValid;
    }
  }
};
