import { errorMessageRequired, errorMessageIncorect } from "./index";

const getDefaultState = () => {
  return {
    stepValidation: {
      isValid: false,
      showErrors: false
    },
    phone: {
      value: "",
      isValid: false,
      errors: []
    },
    firstName: {
      value: "",
      isValid: false,
      errors: []
    },
    middleName: {
      value: ""
    },
    lastName: {
      value: "",
      isValid: false,
      errors: []
    },
    customerType: {
      value: "private"
    },
    email: {
      value: "",
      isValid: false,
      errors: []
    },
    orgNumber: {
      value: "",
      isValid: false,
      errors: []
    },
    comapnyName: {
      value: "",
      isValid: false,
      errors: []
    },
    address: {
      value: "",
      isValid: false,
      errors: []
    },
    postalCode: {
      value: "",
      isValid: false,
      errors: []
    },
    town: {
      value: "",
      isValid: false,
      errors: []
    }
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    updatePhone(state, value) {
      state.phone.value = value;
    },
    validPhone(state) {
      state.phone.errors = [];
      state.phone.isValid = false;

      if (!state.phone.value) {
        state.phone.errors.push(errorMessageRequired);
      } else if (
        state.phone.value.length !== 8 ||
        !/(?:0|[2-9]\d{3})\d{4}/.test(state.phone.value)
      ) {
        state.phone.errors.push(errorMessageIncorect);
      } else {
        state.phone.isValid = true;
      }
    },

    updateFirstName(state, value) {
      state.firstName.value = value;
    },
    validFirstName(state) {
      state.firstName.errors = [];
      state.firstName.isValid = false;

      if (!state.firstName.value) {
        state.firstName.errors.push(errorMessageRequired);
      } else if (state.firstName.value.length < 2) {
        state.firstName.errors.push(errorMessageIncorect);
      } else {
        state.firstName.isValid = true;
      }
    },

    updateMiddleName(state, value) {
      state.middleName.value = value;
    },

    updateLastName(state, value) {
      state.lastName.value = value;
    },
    validLastName(state) {
      state.lastName.errors = [];
      state.lastName.isValid = false;

      if (!state.lastName.value) {
        state.lastName.errors.push(errorMessageRequired);
      } else if (state.lastName.value.length < 2) {
        state.lastName.errors.push(errorMessageIncorect);
      } else {
        state.lastName.isValid = true;
      }
    },

    updateCustomerType(state, value) {
      state.customerType.value = value;
    },

    updateEmail(state, value) {
      state.email.value = value;
    },
    validEmail(state) {
      state.email.errors = [];
      state.email.isValid = false;
      if (!state.email.value) {
        state.email.errors.push(errorMessageRequired);
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email.value)) {
        state.email.errors.push(errorMessageIncorect);
      } else {
        state.email.isValid = true;
      }
    },

    updateOrgNumber(state, value) {
      state.orgNumber.value = value;
    },
    validOrgNumber(state) {
      state.orgNumber.errors = [];
      state.orgNumber.isValid = false;

      if (!state.orgNumber.value) {
        state.orgNumber.errors.push(errorMessageRequired);
      } else if (state.orgNumber.value.length !== 9) {
        state.orgNumber.errors.push(errorMessageIncorect);
      } else {
        state.orgNumber.isValid = true;
      }
    },

    updateComapnyName(state, value) {
      state.comapnyName.value = value;
    },
    validComapnyName(state) {
      state.comapnyName.errors = [];
      state.comapnyName.isValid = false;

      if (!state.comapnyName.value) {
        state.comapnyName.errors.push(errorMessageRequired);
      } else if (state.comapnyName.value.length < 2) {
        state.comapnyName.errors.push(errorMessageIncorect);
      } else {
        state.comapnyName.isValid = true;
      }
    },

    updateAddress(state, value) {
      state.address.value = value;
    },
    validAddress(state) {
      state.address.errors = [];
      state.address.isValid = false;

      if (!state.address.value) {
        state.address.errors.push(errorMessageRequired);
      } else if (state.address.value.length < 2) {
        state.address.errors.push(errorMessageIncorect);
      } else {
        state.address.isValid = true;
      }
    },

    updatePostalCode(state, value) {
      state.postalCode.value = value;
    },
    validPostalCode(state) {
      state.postalCode.errors = [];
      state.postalCode.isValid = false;

      if (!state.postalCode.value) {
        state.postalCode.errors.push(errorMessageRequired);
      } else if (state.postalCode.value.length !== 4) {
        state.postalCode.errors.push(errorMessageIncorect);
      } else {
        state.postalCode.isValid = true;
      }
    },

    updateTown(state, value) {
      state.town.value = value;
    },
    validTown(state) {
      state.town.errors = [];
      state.town.isValid = false;

      if (!state.town.value) {
        state.town.errors.push(errorMessageRequired);
      } else if (state.town.value.length < 2) {
        state.town.errors.push(errorMessageIncorect);
      } else {
        state.town.isValid = true;
      }
    },

    updateStepValidation(state) {
      if (
        state.phone.isValid &&
        state.firstName.isValid &&
        state.lastName.isValid &&
        state.email.isValid &&
        (state.customerType.value === "corporate"
          ? state.orgNumber.isValid && state.comapnyName.isValid
          : true) &&
        state.address.isValid &&
        state.postalCode.isValid &&
        state.town.isValid
      ) {
        state.stepValidation.isValid = true;
        state.stepValidation.showErrors = false;
      } else {
        state.stepValidation.isValid = false;
        state.stepValidation.showErrors = true;
      }
    }
  },

  actions: {
    validStep({ commit, state }) {
      commit("validPhone");
      commit("validFirstName");
      commit("validLastName");
      commit("validEmail");

      if (state.customerType.value === "corporate") {
        commit("validOrgNumber");
        commit("validComapnyName");
      }

      commit("validAddress");
      commit("validPostalCode");
      commit("validTown");

      commit("updateStepValidation");

      return state.stepValidation.isValid;
    }
  }
};
