import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

import step1 from "./step-1";
import step2 from "./step-2";
import step3 from "./step-3";

export const errorMessageRequired = "Dette feltet er påkrevd";
export const errorMessageIncorect = "Denne verdien er ugyldig";

export default new Vuex.Store({
  modules: {
    step1,
    step2,
    step3
  },

  state: {
    formOutput: {
      client: {},
      contact: {},
      project: {}
    }
  },

  mutations: {
    prepareFormOutput(state) {
      state.formOutput.client.name = step1.state.comapnyName.value;
      state.formOutput.client.organization_number = step1.state.orgNumber.value;
      state.formOutput.client.private = step1.state.customerType.value === "private";
      state.formOutput.client.mailing_address1 = step1.state.address.value;
      state.formOutput.client.mailing_address_postal_code = step1.state.postalCode.value;
      state.formOutput.client.mailing_address_town = step1.state.town.value;

      state.formOutput.contact.first_name = step1.state.firstName.value;
      state.formOutput.contact.middle_name = step1.state.middleName.value || "";
      state.formOutput.contact.last_name = step1.state.lastName.value;
      state.formOutput.contact.email = step1.state.email.value;
      state.formOutput.contact.mobile_phone = step1.state.phone.value;

      state.formOutput.project.name = step2.state.projectName.value || "";
      state.formOutput.project.address = step2.state.projectAddress.value;
      state.formOutput.project.postal_code = step2.state.projectPostalCode.value;
      state.formOutput.project.town = step2.state.projectTown.value;
      state.formOutput.project.gnr = step2.state.gnr.value;
      state.formOutput.project.bnr = step2.state.bnr.value;
      state.formOutput.project.municipality = step2.state.municipality.value;

      // Adjustment to BE input requirments
      let coordinates;
      if (step2.state.coordinates.value) {
        coordinates = step2.state.coordinates.value[0].map(
          coordinate => coordinate
        );
      } else {
        coordinates = [];
      }

      state.formOutput.project.coordinates = {
        type: "MultiPolygon",
        coordinates: [[coordinates]],
        crs: {
          type: "name",
          properties: {
            name: "EPSG:25833" // declare output projection
          }
        }
      };

      state.formOutput.project.description = step2.state.projectDescription.value;
      state.formOutput.project.project_types = step2.state.projectTypes.value;
      state.formOutput.request = step3.state.offer.value;
    }
  },

  actions: {
    sendForm({ commit, state }) {
      commit("prepareFormOutput");
      return axios.post(
        process.env.VUE_APP_CONTACT_FORM_API_URL,
        state.formOutput
      );
    },
    sendDrawing(context, { objectId, file }) {
      return axios.post(
        `${process.env.VUE_APP_CONTACT_FORM_API_URL}${objectId}/project_drawing/${file.name}/`,
        file
      );
    },
    sendDocument(context, { objectId, file }) {
      return axios.post(
        `${process.env.VUE_APP_CONTACT_FORM_API_URL}${objectId}/additional_file/${file.name}/`,
        file
      );
    }
  }
});
