<template>
  <div id="app">
    <div class="app__container">

      <div class="tabs">
        <div
          class="tabs__item"
          :class="{ 'tabs__item--active': $route.name === 'Step-1' }"
          @click="goToStep1"
        >
          1. Kontaktperson
        </div>
        <div
          class="tabs__item"
          :class="{ 'tabs__item--active': $route.name === 'Step-2' }"
          @click="goToStep2"
        >
          2. Prosjekt
        </div>
        <div
          class="tabs__item"
          :class="{ 'tabs__item--active': $route.name === 'Step-3' }"
          @click="goToStep3"
        >
          3. Forespørsel
        </div>
      </div>

      <div class="steps">
        <router-view />
      </div>

      <footer class="buttons">
        <BaseButton v-if="showGoBack" type="secondary" @click="goBack">
          Tilbake
        </BaseButton>
        <BaseButton v-if="showNextStep" type="primary" @click="nextStep">
          Neste
        </BaseButton>
        <BaseButton v-if="showSendRequestButton" @click="sendForm">
          Send forespørsel
        </BaseButton>
      </footer>
    </div>

    <notifications
      width="380px"
      group="success"
      position="bottom right"
      classes="notification notification--success"
    />
    <notifications
      width="380px"
      group="warning"
      position="bottom right"
      classes="notification notification--warning"
    />
    <notifications
      width="380px"
      group="error"
      position="bottom right"
      classes="notification notification--error"
    />
  </div>
</template>

<script>
import BaseButton from "./components/BaseButton";
export default {
  components: {
    BaseButton
  },
  computed: {
    showGoBack() {
      return this.$route.name !== "Step-1";
    },
    showNextStep() {
      return this.$route.name !== "Step-3";
    },
    showSendRequestButton() {
      return this.$route.name === "Step-3";
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  },
  methods: {
    goBack() {
      if (this.$route.name === "Step-2") {
        this.goToStep1();
      } else if (this.$route.name === "Step-3") {
        this.goToStep2();
      }
    },
    nextStep() {
      if (this.$route.name === "Step-1") {
        this.goToStep2();
      } else if (this.$route.name === "Step-2") {
        this.goToStep3();
      }
    },

    throwApiError(error) {
      this.$notify({
        title: "Feil",
        text: "Det har dessverre skjedd en feil under innsending. Vennligst prøv igjen eller ta kontakt med oss på post@era-geo.no",
        group: "error"
      });
      console.error(error);
    },

    goToStep1() {
      if (this.$route.name !== "Step-1") {
        this.$router.push({ name: "Step-1" });
      }
    },
    async goToStep2() {
      if (this.$route.name !== "Step-2") {
        const isValid = await this.$store.dispatch("step1/validStep");

        if (isValid || this.$route.name === "Step-3") {
          this.$router.push({ name: "Step-2" });
        } else {
          this.$notify({
            title: "Ugyldig data",
            text: "Vennligst sjekk innputen",
            group: "error"
          });
        }
      }
    },
    async goToStep3() {
      if (this.$route.name !== "Step-3") {
        const isValid =
          (await this.$store.dispatch("step2/validStep")) &&
          (await this.$store.dispatch("step1/validStep"));

        if (isValid) {
          this.$router.push({ name: "Step-3" });
        } else {
          this.$notify({
            title: "Ugyldig data",
            text: "Vennligst sjekk innputen",
            group: "error"
          });
        }
      }
    },

    async sendForm() {
      const isValid =
        (await this.$store.dispatch("step3/validStep")) &&
        (await this.$store.dispatch("step2/validStep")) &&
        (await this.$store.dispatch("step1/validStep"));

      if (isValid) {
        await this.$store
          .dispatch("sendForm")
          .then(async response => {
            const queue = [];
            const objectId = response.data.pk;
            const drawing = this.$store.state.step2.projectDrawing.value;
            const documents = this.$store.state.step3.documents.value;

            if (drawing.length) {
              queue.push(
                this.$store.dispatch("sendDrawing", { file: drawing[0], objectId })
              );
            }

            if (documents.length) {
              documents.forEach(file => {
                queue.push(
                  this.$store.dispatch("sendDocument", { file, objectId })
                );
              });
            }

            Promise.all(queue)
              .then(() => {
                this.$notify({
                  title: "Suksess",
                  text: "Skjemaet er sendt. Du vil få en bekreftelse på e-post.",
                  group: "success"
                });

                this.$store.commit("step3/resetState");
                this.$store.commit("step2/resetState");
                this.$store.commit("step1/resetState");

                setTimeout(() => {
                  this.$router.push({ name: "Step-1" });
                }, 3000);
              })
              .catch(error => {
                this.throwApiError(error);
              });
          })
          .catch(error => {
            this.throwApiError(error);
          });
      } else {
        this.$notify({
          title: "Ugyldig data",
          text: "Vennligst sjekk innputen",
          group: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap");
@import "~normalize.css";
@import "src/assets/scss/variables";

body {
  background-color: $color-background;
}

#app {
  font-family: "Rubik", sans-serif;
  color: $color-font;
  padding: 85px 0;
  display: flex;
  justify-content: center;
  position: relative;

  &::before {
    content: "";
    background-color: $color-primary;
    width: 100%;
    height: 396px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.app__container {
  width: 460px;
}

.logo {
  background-color: white;
  height: 60px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.welcome {
  margin-top: 60px;
  margin-bottom: 40px;

  h2 {
    font-weight: 500;
    font-size: 28px;
    line-height: 20px;
  }
}

.tabs {
  display: flex;

  @media (max-width: 475px) {
    flex-direction: column;
  }

  .tabs__item {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    margin-right: 10px;
    color: $color-font;
    text-decoration: none;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    white-space: nowrap;
    cursor: pointer;

    @media (max-width: 475px) {
      padding: 5px 0;
    }
  }

  .tabs__item--active {
    opacity: 1;
  }

  .tabs__item--disabled {
    cursor: not-allowed;
  }
}

.welcome, .tabs {
  @media (max-width: 475px) {
    padding: 0 40px;
  }
}

.steps {
  background-color: white;
  margin-top: 30px;
  padding: 40px;
  border-radius: 4px;
}

.step-group {
  &:not(:last-child) {
    margin-bottom: 25px;
  }

  > * {
    margin-bottom: 10px;
  }
}

.step-group--error {
  .label {
    color: $color-error;
  }

  animation: scale 0.3s ease-in-out;

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }
}

.label {
  font-weight: 500;
  margin-bottom: 12px;
}

.buttons {
  margin: 40px 0;
  display: flex;
  justify-content: flex-end;

  > * {
    margin-left: 15px;
  }
}

.error-message {
  color: $color-error;
  font-size: 12px;

  > div {
    margin: 5px 0;
  }
}

.notification {
  border-radius: 4px;
  padding: 20px;
  margin: 20px;
  color: white;
}

.notification-content {
  font-size: 14px;
  margin-top: 5px;
}

.notification--error {
  background-color: $color-error !important; // important needed for override default color
  border-left: 6px solid darken($color-error, 10%);
}

.notification--warning {
  background-color: $color-warning !important; // important needed for override default color
  border-left: 6px solid darken($color-warning, 10%);
}

.notification--success {
  background-color: $color-success !important; // important needed for override default color
  border-left: 6px solid darken($color-success, 10%);
}
</style>
